<template lang="pug">
.template-chooser-home-wrapper
  TopFilter(
    v-if="!isFilterActive && !search"
    @messageTypeFilter="$emit('messageTypeFilter', $event)"
    @imagePopup="$emit('createCampaign', 'flyer')"
  )
  Skeleton(v-if="isDataLoading")
  ListEmptyState(
    v-else-if="!hasResults"
    :image="require('@/assets/admin/svg/monk_magnifying.svg')"
    :title="$t('templateChooser.emptyStateBaseTitle')"
  )
  component.block(
    v-if="!isDataLoading"
    v-for="(block, index) in blocks"
    ref="blocks"
    :is="block"
    :key="block"
    :filter="filter"
    :search="search"
    rootMargin="300px"
    @loaded="onComponentLoaded"
    @navigateWithFilterReset="$emit('navigateWithFilterReset', $event)"
    @createDynamicContent="createDynamicContent()"
    @track="$emit('trackActivity', $event)"
    @filter="$emit('filter', $event)"
    @templateCount="onTemplateCount"
  )
</template>

<script>
  import { track } from '@/services/xray';
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import { debounce } from 'lodash-es';
  import filterHelperMixin from '../mixins/filterHelper';

  const PAGE_PERSONALIZATION_TYPE = 'page';
  const AB_TEST_PERSONALIZATION_TYPE = 'abTest';

  const BLOCK_UPCOMING_FILTERED = 'upcoming-seasons-filtered';
  const BLOCK_UPCOMING_BANNERS = 'upcoming-seasons-banners';
  const BLOCK_RECOMMENDED_USE_CASES = 'recommended-use-cases';
  const BLOCK_TEMPLATE_FAMILIES = 'template_families';
  const BLOCK_GOALS = 'goals';
  const BLOCK_USE_CASES_FILTERED = 'use-cases-filtered';
  const BLOCK_YOUR_THEMES = 'your-themes';
  const BLOCK_RESELLER_TEMPLATES = 'reseller-templates';

  const BLOCK_FEW_RESULT = 'few-result';

  const UNFILTERED_HOME = [
    BLOCK_TEMPLATE_FAMILIES,
    BLOCK_RESELLER_TEMPLATES,
    BLOCK_UPCOMING_BANNERS,
    BLOCK_RECOMMENDED_USE_CASES,
    BLOCK_YOUR_THEMES,
    BLOCK_GOALS,
  ];

  const FILTERED_HOME_MORE_RESULT = [
    BLOCK_YOUR_THEMES,
    BLOCK_UPCOMING_FILTERED,
    BLOCK_USE_CASES_FILTERED,
  ];

  const FILTERED_HOME_FEW_RESULT = [BLOCK_FEW_RESULT];

  export default {
    components: {
      [BLOCK_RECOMMENDED_USE_CASES]: () =>
        import('@/components/TemplateChooser/components/blocks/RecommendedUseCases.vue'),
      [BLOCK_YOUR_THEMES]: () =>
        import('@/components/TemplateChooser/components/blocks/YourThemes.vue'),
      [BLOCK_UPCOMING_BANNERS]: () =>
        import('@/components/TemplateChooser/components/blocks/UpcomingSeasonsBanners.vue'),
      [BLOCK_UPCOMING_FILTERED]: () =>
        import('@/components/TemplateChooser/components/blocks/UpcomingSeasonsFiltered.vue'),
      [BLOCK_TEMPLATE_FAMILIES]: () =>
        import('@/components/TemplateChooser/components/blocks/ThemesFamilies.vue'),
      [BLOCK_GOALS]: () => import('@/components/TemplateChooser/components/blocks/Goals.vue'),
      [BLOCK_USE_CASES_FILTERED]: () =>
        import('@/components/TemplateChooser/components/blocks/UseCasesFiltered.vue'),
      [BLOCK_FEW_RESULT]: () =>
        import('@/components/TemplateChooser/components/blocks/FewResult.vue'),
      [BLOCK_RESELLER_TEMPLATES]: () =>
        import('@/components/TemplateChooser/components/blocks/ResellerTemplates.vue'),
      TopFilter: () => import('@/components/TemplateChooser/components/TopFilter.vue'),
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
      ListEmptyState: () => import('@/components/TemplateChooser/components/ListEmptyState.vue'),
    },
    mixins: [filterHelperMixin],
    data() {
      return {
        PAGE_PERSONALIZATION_TYPE,
        AB_TEST_PERSONALIZATION_TYPE,
        hasResults: true,
        blocksLoading: true,
        accumulatedCount: 0,
        typeCounts: {},
      };
    },
    computed: {
      ...mapGetters(['getLocale']),
      ...mapGetters('templateChooser', ['loading', 'resultCount', 'displayedTemplates']),
      ...mapState('templateChooser', ['search', 'filter']),
      ...mapState('useCase', ['useCaseLoading']),
      isFilterActive() {
        return this.isActiveFilters || this.search;
      },
      blocks() {
        if (!this.isFilterActive) {
          return UNFILTERED_HOME;
        }

        if (this.resultCount > 50) {
          return FILTERED_HOME_MORE_RESULT;
        }

        return FILTERED_HOME_FEW_RESULT;
      },
      isDataLoading() {
        return this.loading || this.useCaseLoading;
      },
    },
    watch: {
      filter: {
        handler() {
          this.RESET_DISPLAYED_TEMPLATES();
        },
        deep: true,
      },
      search: {
        handler() {
          this.RESET_DISPLAYED_TEMPLATES();
        },
      },
    },
    created() {
      this.debouncedTrack = debounce(this.onTrack, 1500);
    },
    mounted() {
      this.RESET_DISPLAYED_TEMPLATES();
      this.$emit('setLoading', false);
    },
    beforeDestroy() {
      this.RESET_DISPLAYED_TEMPLATES();
    },
    methods: {
      ...mapMutations('templateChooser', ['ADD_DISPLAYED_TEMPLATE', 'RESET_DISPLAYED_TEMPLATES']),
      onTemplateCount({ type, count }) {
        if (!this.search) return;

        this.accumulatedCount += count;

        if (!this.typeCounts[type]) {
          this.typeCounts[type] = 0;
        }

        this.typeCounts[type] += count;
        this.debouncedTrack();
      },
      onTrack() {
        const typeCounts = Object.keys(this.typeCounts).map((type) => ({
          type,
          count: this.typeCounts[type],
        }));

        track('template_selector_click', {
          component: 'Search',
          setting: 'start',
          options: this.search,
          location: 'main',
          totalCount: this.accumulatedCount,
          typeCounts,
        });

        this.accumulatedCount = 0;
        this.typeCounts = {};
      },
      openLink(url) {
        window.open(url, '_blank').focus();
      },
      createDynamicContent(addControlVariant = false) {
        this.$modal.show('name-campaign', {
          canChange: false,
          isDynamicContent: true,
          addControlVariant,
        });
      },
      onComponentLoaded(child) {
        if (child) {
          this.hasResultsInBlocks();
        }
      },
      hasResultsInBlocks: debounce(function () {
        this.hasResults = this.$refs.blocks?.some?.((block) => {
          const possibleTemplates = block?.getPossibleTemplates?.() ?? [];
          return !!possibleTemplates?.length;
        });
      }, 50),
    },
  };
</script>
<style lang="sass">
  .block
    margin-bottom: 3.75rem
</style>
