export default {
  computed: {
    isActiveFilters() {
      return (
        this.filter.types.length > 0 ||
        this.filter.goals.length > 0 ||
        this.filter.contents.length > 0
      );
    },
  },
  methods: {
    getPossibleTemplates() {
      throw new Error('Implement this method');
    },
  },
};
